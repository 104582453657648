<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <div class="row">
        <div class="col">
          <h6>LISTADO DE TRABAJOS DISPONIBLES</h6>
        </div>
        <div class="col">
          <button  class="btn bg-gradient-success float-end" @click="$router.push('/create-jobs')"> Crear nuevo trabajo</button>
        </div>
      </div>
    </div>
    <div class="card-body px-4">
      <div class="row justify-content-end">
        <div class="col-lg-4 col-md-6 col-sm-12">
          <input type="text" class="form-control" placeholder="Buscar por nombre" v-model="pagination.search" @keyup="manualSearch">
        </div>
        <div class="col-lg-2 col-md-2 col-sm-12">
          <select name="per_page" id="per_page" v-model="pagination.limit" class="form-control" @click="reloadPaginate(1)">
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
          </select>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-hover table-sm border table-bordered">
          <thead>
          <tr>
            <th class="text-left">
              Compañia
            </th>
            <th class="text-left">
              Ubicacion
            </th>
            <th class="text-left">
              Sueldo
            </th>
            <th class="text-left">
              Descripcion
            </th>
            <th class="text-left">
              Opciones
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in array_jobs" :key="item.name">
            <td>{{ item.name }}</td>
            <td>{{ item.location }}</td>
            <td>
              <div class="limited-width_20">
                {{ item.salary }}
              </div>
            </td>
            <td><div class="limited-width_50">
              {{ item.description }}
            </div></td>
            <td>
              <button class="btn btn-outline-danger btn-sm mb-0" @click="deleteItem(item)">
                <i class="fas fa-trash"></i>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="d-flex justify-content-between">
        <div>
          <p class="text-xs font-weight-bold mb-0">total rows: {{pagination.totalRows}}</p>
        </div>
        <div>
          <v-pagination
              v-model="pagination.page"
              :pages="pagination.pages"
              :range-size="1"
              active-color="#DCEDFF"
              @update:modelValue="reloadPaginate"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import {get, post} from "@/utils/httpRequest";

export default {
  data: () => ({
    array_jobs: [],
    pagination: {
      searchTimer: null,
      page: 1,
      pages: 0,
      limit: 20,
      search: '',
      totalRows: 0
    },
  }),
  mounted() {
    this.getData()
  },
  methods: {
    reloadPaginate(page){
      this.pagination.page = page
      this.getData()
    },
    manualSearch(){
      clearTimeout(this.pagination.searchTimer);
      this.pagination.searchTimer = setTimeout(() => {
        this.reloadPaginate(1)
      }, 2000);
    },
    async getData(){
      const {data} = await get(`job-offers?page=${this.pagination.page}&limit=${this.pagination.limit}&search=${this.pagination.search}`, null)
      this.array_jobs = data.docs
      this.pagination.totalRows = data.count
      this.pagination.page = data.page
      this.pagination.pages = data.pages
    },
    async deleteItem(item){
      await Swal.fire({
        title: 'Esta seguro?',
        text: "No podras revertir esto!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#82d616',
        cancelButtonColor: '#d33',
        confirmButtonText: 'SI',
        cancelButtonText: 'NO'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await post("job-offers/remove",{_id : item._id})
          await this.getJobs()
          await Swal.fire('Informacion eliminada correctamente', '', 'success')
        }
      })
    }
  }
}
</script>
<style scoped>

</style>
